import React from 'react';
import { 
  createTheme
} from '@mui/material/styles';

import {
  Box,
	Typography
} from '@mui/material';
import { Parallax } from 'react-parallax';

const theme = createTheme();

export default function FrontPage() {
  return (
    <Parallax
      bgImage={require('../images/ReachingForFish.webp')}
      strength={400}
    >
      <Box sx={{
        height: '100vh',
        display: 'grid',
        alignItems: 'center',
        textAlign: 'center',
        color: 'white',
      }}>
        <Box 
          sx={{
            background: 'radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2)
          }}
        >
          <Typography variant="h3">Matthew Paul White</Typography>
          <Typography variant="h4">Solutions Architect | London</Typography>
        </Box>
      </Box>
    </Parallax>
  );
}