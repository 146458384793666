import React from 'react';
import { 
  createTheme
} from '@mui/material/styles';

import {
  Container,
	Typography,
  Paper,
  Grid,
  IconButton,
  Avatar,
  Tooltip
} from '@mui/material';

import Me from '../images/Me.webp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

const theme = createTheme();

export default function About() {
  
  const personal = "Hello there, I'm Matthew, and I'm delighted you've found your way to my site. I was raised in the beautiful county of Suffolk, and I'm a true country boy at heart. Now happily married, I live in London with my two adorable sausage dogs, Klaus and Agnes. My passion for traveling, history, and immersing myself in diverse cultures has led me to learn conversational Thai and explore the world beneath the waves through diving. When I'm not adventuring or spending quality time with my beloved wife, you can find me enjoying my status as an avid tea drinker and a cautious coffee enthusiast - though I tend to keep the latter a bit hush-hush!";
  const professional = "A Solutions Architect with ten years of experience, I excel at leading global projects with millions of users, managing C-level relationships, and navigating complex stakeholder landscapes. With proven proficiency in cloud-native solutions, serverless functions, infrastructure as code, and a background in hands-on development using React and Node.js, I successfully drive projects from concept to completion. My strategic approach to project management is underscored by Agile methodologies, S.O.L.I.D principles, TDD, and Scrum. Known for my ability to communicate effectively with both technical and non-technical stakeholders, I am adept at leading multicultural teams across different time zones and delivering high-impact results.";

  return (
    <div>
      <meta name="description" content="About section provides a summary of me both personally and professionally." />
      <Container 
        id="About"
        maxWidth="lg" 
        sx={{
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2)
        }} 
      >
        <Typography gutterBottom variant="h3" align="center">
          About me
        </Typography>
        <Paper sx={{
          padding: theme.spacing(2),
          margin: 'auto'
        }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h4">
                Personal
              </Typography>
              <Typography variant="body1">
                {personal.split("\n").map((i, key) => {
                  return <p key={key}>{i}</p>;
                })}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Avatar 
                sx={{
                  width: '100%',
                  height: '100%'
                }} 
                alt="complex" 
                src={Me} 
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography variant="h4">
                Professional
              </Typography>
              <Typography variant="body1">
                {professional.split("\n").map((i, key) => {
                  return <p key={key}>{i}</p>;
                })}
              </Typography>
              <Grid container sx={{
                justifyContent: 'center'
              }}>
                <Tooltip title="LinkedIn">
                  <IconButton 
                    color="primary"
                    href="https://www.linkedin.com/in/matthew-white-ba30a95a/" 
                    target="_blank" 
                    rel="noreferrer"
                    aria-label="LinkedIn"
                  >
                    <LinkedInIcon/>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Credly">
                  <IconButton 
                    color="primary"
                    href="https://www.credly.com/users/matthew-white.1230beac/badges#" 
                    target="_blank" 
                    rel="noreferrer"
                    aria-label="Credly"
                  >
                    <WorkspacePremiumIcon/>
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Paper> 
      </Container>
    </div>
  );
}
